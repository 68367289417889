import {createApp} from 'vue'
import App from './App.vue'

import router from './router'
import store from './store'

import bootstrap from 'bootstrap/dist/js/bootstrap.js'
import {library} from '@fortawesome/fontawesome-svg-core'
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import {
    faChevronRight,
    faChevronLeft,
    faChevronDown,
    faMountainCity,
    faPhone,
    faEnvelope,
    faCircleChevronLeft,
    faCircleChevronRight,
    faCirclePlay,
    faCheck,
    faXmark,
    faLocationDot,
    faShield,
    faUserTie,
    faMicrochip,
    faBorderAll,
    faHourglassEnd,
    faTruck,
    faListCheck,
    faFlask,
    faPen,
    faWaveSquare,
    faSquarePlus,
    faClipboardList
} from '@fortawesome/free-solid-svg-icons'

import {asset} from '@/util/helpers'

library.add(
    faChevronRight,
    faChevronLeft,
    faChevronDown,
    faMountainCity,
    faPhone,
    faEnvelope,
    faCircleChevronLeft,
    faCircleChevronRight,
    faCirclePlay,
    faCheck,
    faXmark,
    faLocationDot,
    faShield,
    faUserTie,
    faMicrochip,
    faBorderAll,
    faHourglassEnd,
    faTruck,
    faListCheck,
    faFlask,
    faPen,
    faWaveSquare,
    faSquarePlus,
    faClipboardList
)

createApp(App)
    .use(store)
    .use(router)
    .use(bootstrap)
    .component('font-awesome-icon', FontAwesomeIcon)
    .mixin({
        methods: {
            asset
        }
    })
    .mount('#app')
