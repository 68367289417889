import {createRouter, createWebHistory} from 'vue-router'

const routes = [
    {
        path: '/',
        name: 'home',
        meta: {
            layout: 'App',
            title: 'Գլխավոր էջ'
        },
        component: () => import('../views/home/HomeView.vue')
    },
    {
        path: '/projects',
        name: 'projects',
        meta: {
            layout: 'App',
            title: 'Պրոեկտներ'
        },
        component: () => import('../views/projects/ProjectsView.vue')
    },
    {
        path: '/projects/:id',
        name: 'project',
        meta: {
            layout: 'App',
            title: 'Պրոեկտ'
        },
        component: () => import('../views/project/ProjectView.vue')
    },
    {
        path: '/concrete',
        name: 'concrete',
        meta: {
            layout: 'App',
            title: 'Բետոն'
        },
        component: () => import('../views/concrete/ConcreteView.vue')
    },
    {
        path: '/about',
        name: 'about',
        meta: {
            layout: 'App',
            title: 'Մեր մասին'
        },
        component: () => import('../views/about/AboutView.vue')
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
