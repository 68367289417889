export const CONST_LINKS = Object.freeze([
    {
        title: 'Գլխավոր էջ',
        url: {name: 'home'}
    },
    {
        title: 'Պրոեկտներ',
        url: {name: 'projects'}
    },
    {
        title: 'Բետոն',
        url: {name: 'concrete'}
    },
    {
        title: 'Մեր մասին',
        url: {name: 'about'}
    }
])

export const CONST_BACKGROUND_TYPES = Object.freeze({
    video: 0,
    image: 1
})
