<template>
  <component :is="layout">
    <router-view/>
  </component>
</template>
<script>
import App from '@/layouts/App'

import {app} from "@/configs/app";

export default {
  components: {
    App
  },
  computed: {
    layout() {
      return this.$route.meta.layout
    }
  },
  mounted() {
    window.addEventListener('scroll', this.animateIfInViewport)
  },
  watch: {
    $route: {
      immediate: true,
      handler(to, from) {
        if (to.meta.title) {
          document.title = app.title + ' | ' + to.meta.title
        } else {
          document.title = app.title
        }
      }
    },
  },
  methods: {
    animateIfInViewport() {
      const elements = document.querySelectorAll('.appearance-animated')

      for (const element of elements) {
        if (this.isElementInViewport(element)) {
          element.classList.add('appearance-animated-in-viewport')
        } else {
          element.classList.remove('appearance-animated-in-viewport')
        }
      }
    },
    isElementInViewport(element) {
      const rect = element.getBoundingClientRect();

      return (
          rect.top >= 0 &&
          rect.left >= 0 &&
          rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
          rect.right <= (window.innerWidth || document.documentElement.clientWidth)
      );
    }
  }
}
</script>
<style>
@import "~bootstrap/dist/css/bootstrap.css";
@import "assets/styles/style.css";
</style>
