<template>
  <footer class="w-100">
    <div class="container py-5 px-0">
      <ul class="nav justify-content-center border-bottom pb-3 mb-3">
        <li v-for="(link, index) in links" :key="index" class="nav-item">
          <router-link :to="link.url" class="nav-link text-body-secondary">{{ link.title }}</router-link>
        </li>
      </ul>
      <div class="col-12 d-flex align-items-start justify-content-start pb-3 px-3">
        <div class="d-flex flex-column align-items-start justify-content-center">
          <p class="text-body-secondary pb-4 m-0">
            <font-awesome-icon icon="fa-solid fa-location-dot" class="pe-2"/>
            <span class="fw-bold">Հասցե։</span> Ք․ Վանաձոր, Տավրոս թաղամաս 22 փողոց 3/2
          </p>
          <div class="d-flex align-items-start justify-content-center pb-4 text-body-secondary">
            <p class="m-0">
              <font-awesome-icon icon="fa-solid fa-phone" class="pe-2"/>
              <span class="fw-bold">Հեռախոսահամար։</span>
            </p>
            <div class="d-flex flex-column align-items-center justify-content-center ps-1">
              <span>(+374) 91-00-59-59</span>
              <span>(+374) 77-86-62-22</span>
            </div>
          </div>
          <p class="text-body-secondary m-0">
            <font-awesome-icon icon="fa-solid fa-envelope" class="pe-2"/>
            <span class="fw-bold">Էլ․ հասցե։</span> r.ghazaryanllc@mail.ru
          </p>
        </div>
      </div>
      <p class="text-center text-body-secondary">© {{ currentYear }} Company, Inc</p>
    </div>
  </footer>
</template>

<script>
import {CONST_LINKS} from "@/util/constants";

export default {
  name: 'FooterComponent',
  setup() {
    return {
      links: CONST_LINKS,
      currentYear: new Date().getFullYear()
    }
  }
}
</script>

<style scoped>
</style>
