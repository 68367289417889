<template>
  <header :class="['w-100', 'position-relative', 'py-5', rellaxConfigs.className]"
          data-rellax-speed="7">
    <nav class="navbar navbar-expand-lg header-navbar">
      <div class="container">
        <router-link to="/" class="navbar-brand header-logo-text">R. GHAZARYAN</router-link>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse"
                aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarCollapse">
          <ul class="navbar-nav ms-auto">
            <li v-for="(link, index) in links" :key="index" class="nav-item">
              <router-link :to="link.url" activeClass="header-link-active" class="nav-link header-link">{{
                  link.title
                }}
              </router-link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
    <section class="container py-5">
      <div class="row align-items-center justify-content-center g-0">
        <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 row g-0">
          <p :class="['col-12', 'title', 'text-white', 'm-0', 'pb-3', rellaxConfigs.className]" data-rellax-speed="4">
            Նորարարություն և ճշգրտություն՝ ամեն շենքում և ճանապարհում</p>
          <p :class="['col-12', 'text-white', 'm-0', 'pb-4', rellaxConfigs.className]"
             data-rellax-speed="3">Բարձր որակ, տեխնոլոգիական լուծումներ և հաջողություն՝ յուրաքանչյուր նախագծում</p>
          <div
              :class="['col-12', 'row', 'align-items-center', 'justify-content-center', 'pt-3', rellaxConfigs.className]"
              data-rellax-speed="2">
            <div class="col-6">
              <a href="tel:+37491005959" class="w-100 d-block text-center text-decoration-none p-2 header-button">
                <font-awesome-icon icon="fa-solid fa-phone" class="pe-2"/>
                Զանգահարել
              </a>
            </div>
            <div class="col-6">
              <a href="mailto:r.ghazaryanllc@mail.ru" target="_blank"
                 class="w-100 d-block text-center text-decoration-none p-2 header-button-light">
                <font-awesome-icon icon="fa-solid fa-envelope" class="pe-2"/>
                Գրել
              </a>
            </div>
          </div>
        </div>
        <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
        </div>
      </div>
    </section>
    <video v-if="appConfigs.header.background.type === backgroundTypes.video" autoplay muted loop
           class="background-video" preload="auto">
      <source :src="appConfigs.header.background.src" type="video/mp4">
    </video>
    <img v-else :src="appConfigs.header.background.src" class="background-image" alt="Background image">
  </header>
</template>

<script>
import {onMounted} from "vue";

import Rellax from 'rellax/rellax'

import {rellax} from "@/configs/rellax";
import {CONST_LINKS} from "@/util/constants";
import {CONST_BACKGROUND_TYPES} from "@/util/constants";
import {app} from "@/configs/app";

export default {
  name: 'HeaderComponent',
  setup() {
    onMounted(() => {
      if (rellax.useRellax) {
        const rellax = new Rellax('.' + rellax.className);
      }
    })

    return {
      links: CONST_LINKS,
      backgroundTypes: CONST_BACKGROUND_TYPES,
      appConfigs: app,
      rellaxConfigs: rellax
    }
  }
}
</script>

<style scoped>
.header-navbar {
  background-color: transparent;
}

.header-logo-text {
  color: #ffffff;
  font-weight: 600;
}

.header-logo-text:hover, .header-logo-text:focus {
  color: #ffffff !important;
}

.navbar-toggler {
  background-color: #ffffff !important;
}

.header-link {
  color: #ffffff;
  font-weight: 600;
  transition: opacity .5s;
}

.header-link:hover {
  color: #ffffff;
  opacity: 0.5;
}

.header-link-active {
  color: #ffffff !important;
  border-bottom: 2px solid #ffffff;
}

.header-button {
  color: #ffffff;
  background-color: #262263;
  border: 1px solid #262263;
  border-radius: 3rem;
  font-weight: 600;
  transition: opacity .5s;
}

.header-button:hover {
  opacity: 0.5;
}

.header-button-light {
  color: #ffffff;
  background-color: transparent;
  border: 3px solid #ffffff;
  border-radius: 3rem;
  font-weight: 600;
  transition: all .5s;
}

.header-button-light:hover {
  background-color: #262263;
  border-color: #262263;
}
</style>
