import {CONST_BACKGROUND_TYPES} from "@/util/constants";

export const app = {
    title: 'R. GHAZARYAN',
    header: {
        background: {
            type: CONST_BACKGROUND_TYPES.image,
            src: require('@/assets/images/header-background.jpg')
        }
    },
    presentation: {
        images: [
            {src: require('@/assets/images/presentation-1.jpg')},
            {src: require('@/assets/images/presentation-2.jpeg')}
        ]
    },
    captions: {
        background: {
            type: CONST_BACKGROUND_TYPES.image,
            src: require('@/assets/images/captions-background.png')
        }
    },
    gallery: {
        image: {src: require(`@/assets/images/gallery.png`)},
    },
    carousel: {
        images: [
            {src: require(`@/assets/images/carousel-1.jpg`)},
            {src: require(`@/assets/images/carousel-2.jpg`)},
            {src: require(`@/assets/images/carousel-3.jpg`)},
            {src: require(`@/assets/images/carousel-4.jpg`)}
        ]
    },
    features: {
        background: {
            src: require('@/assets/images/features-background.jpg')
        }
    },
    videos: {
        videos: [
            {
                thumbnailSrc: require('@/assets/images/thumbnail-1.png'),
                src: require('@/assets/videos/video-1.mp4')
            },
            {
                thumbnailSrc: require('@/assets/images/thumbnail-2.png'),
                src: require('@/assets/videos/video-2.mp4')
            },
            {
                thumbnailSrc: require('@/assets/images/thumbnail-3.png'),
                src: require('@/assets/videos/video-3.mp4')
            },
            {
                thumbnailSrc: require('@/assets/images/thumbnail-4.png'),
                src: require('@/assets/videos/video-4.mp4')
            }
        ]
    }
}
