export default {
    state: {
        projects: [
            {
                id: 1,
                title: 'Շինություն Իդեալ համակարգի հարևանությամբ',
                description: 'Շինության կառուցման աշխատանքներ ք․ Վանաձորում, Իդեալ համակարգի հարևանությամբ',
                texts: [],
                thumbnail: require('@/assets/images/projects/close-ideal/1.jpg'),
                images: [
                    {src: require('@/assets/images/projects/close-ideal/1.jpg')},
                    {src: require('@/assets/images/projects/close-ideal/2.jpg')},
                    {src: require('@/assets/images/projects/close-ideal/3.jpg')},
                    {src: require('@/assets/images/projects/close-ideal/4.jpg')},
                    {src: require('@/assets/images/projects/close-ideal/5.jpg')},
                    {src: require('@/assets/images/projects/close-ideal/6.jpg')},
                    {src: require('@/assets/images/projects/close-ideal/7.jpg')},
                    {src: require('@/assets/images/projects/close-ideal/8.jpg')},
                    {src: require('@/assets/images/projects/close-ideal/9.jpg')}
                ]
            },
            {
                id: 2,
                title: 'Վանաձոր համայնքի թիվ 8 մանկապարտեզ',
                description: 'Վանաձոր համայնքի թիվ 8 մանկապարտեզ նոր մասնաշենքի կառուցման աշխատանքներ',
                texts: [
                    {
                        title: 'Ընդհանուր մակերեսը',
                        text: '960x2 քմ'
                    },
                    {
                        title: 'Պատվիրատու',
                        text: 'Վանաձորի համայնքապետարան'
                    },
                    {
                        title: 'Կատարման տարեթիվ',
                        text: '1-ին փուլ - 2022, 2-րդ փուլ - 2024'
                    }
                ],
                thumbnail: require('@/assets/images/projects/kindergarten/1.jpg'),
                images: [
                    {src: require('@/assets/images/projects/kindergarten/1.jpg')},
                    {src: require('@/assets/images/projects/kindergarten/2.jpg')},
                    {src: require('@/assets/images/projects/kindergarten/3.jpg')},
                    {src: require('@/assets/images/projects/kindergarten/4.jpg')},
                    {src: require('@/assets/images/projects/kindergarten/5.jpg')},
                    {src: require('@/assets/images/projects/kindergarten/6.jpg')},
                    {src: require('@/assets/images/projects/kindergarten/7.jpg')},
                    {src: require('@/assets/images/projects/kindergarten/8.jpg')},
                    {src: require('@/assets/images/projects/kindergarten/9.jpg')}
                ]
            },
            {
                id: 4,
                title: 'Բենզալցակայան ք․ Վանաձորում',
                description: 'Բենզալցակայանի կառուցապատման աշխատանքներ ք․ Վանաձորում',
                texts: [],
                thumbnail: require('@/assets/images/projects/gasoline-1/1.jpg'),
                images: [
                    {src: require('@/assets/images/projects/gasoline-1/1.jpg')},
                    {src: require('@/assets/images/projects/gasoline-1/2.jpg')},
                    {src: require('@/assets/images/projects/gasoline-1/3.jpg')}
                ]
            },
            {
                id: 5,
                title: 'Վանաձոր համայնքի Սայաթ-Նովայի անվան զբոսայգի',
                description: 'Հիմնանորոգման աշխատանքներ Վանաձոր համայնքի Սայաթ-Նովայի անվան զբոսայգում',
                texts: [
                    {
                        title: 'Ընդհանուր մակերեսը',
                        text: '13165 քմ'
                    },
                    {
                        title: 'Պատվիրատու',
                        text: 'Վանաձորի համայնքապետարան'
                    },
                    {
                        title: 'Կատարման տարեթիվ',
                        text: '2021-2022'
                    }
                ],
                thumbnail: require('@/assets/images/projects/city-garden/2.jpg'),
                images: [
                    {src: require('@/assets/images/projects/city-garden/1.jpg')},
                    {src: require('@/assets/images/projects/city-garden/2.jpg')},
                    {src: require('@/assets/images/projects/city-garden/3.jpg')},
                    {src: require('@/assets/images/projects/city-garden/4.jpg')},
                    {src: require('@/assets/images/projects/city-garden/5.jpg')},
                    {src: require('@/assets/images/projects/city-garden/6.jpg')},
                    {src: require('@/assets/images/projects/city-garden/7.jpg')},
                    {src: require('@/assets/images/projects/city-garden/8.jpg')},
                    {src: require('@/assets/images/projects/city-garden/9.jpg')}
                ]
            },
            {
                id: 6,
                title: 'Վանաձոր քաղաքային համայնքի Կ.Դեմիրճյան փողոց',
                description: 'Հիմնանորոգման աշխատանքներ Վանաձոր քաղաքային համայնքի Կ.Դեմիրճյան փողոցում',
                texts: [
                    {
                        title: 'Ընդհանուր երկարություն',
                        text: '3,9 կմ'
                    },
                    {
                        title: 'Ընդհանուր մակերեսը',
                        text: '66000 քմ'
                    },
                    {
                        title: 'Պատվիրատու',
                        text: 'ՀՀ տարածքային կառավարման և ենթակառուցվածքների նախարարություն'
                    },
                    {
                        title: 'Կատարման տարեթիվ',
                        text: '2023'
                    }
                ],
                thumbnail: require('@/assets/images/projects/demirchyan-street/1.jpg'),
                images: [
                    {src: require('@/assets/images/projects/demirchyan-street/1.jpg')},
                    {src: require('@/assets/images/projects/demirchyan-street/2.jpg')},
                    {src: require('@/assets/images/projects/demirchyan-street/3.jpg')},
                    {src: require('@/assets/images/projects/demirchyan-street/4.jpg')},
                    {src: require('@/assets/images/projects/demirchyan-street/5.jpg')},
                    {src: require('@/assets/images/projects/demirchyan-street/6.jpg')},
                    {src: require('@/assets/images/projects/demirchyan-street/7.jpg')},
                    {src: require('@/assets/images/projects/demirchyan-street/8.jpg')},
                    {src: require('@/assets/images/projects/demirchyan-street/9.jpg')}
                ]
            },
            {
                id: 7,
                title: 'Domus առևտրի կենտրոն',
                description: 'Ք. Վանաձոր, Բաղրամյան պողոտա 60 հասցեում առևտրի կենտրոնի կառուցման շինարարական աշխատանքներ',
                texts: [
                    {
                        title: 'Հասցե',
                        text: 'ք. Վանաձոր, Բաղրամյան պողոտա 60'
                    },
                    {
                        title: 'Ընդհանուր մակերեսը',
                        text: '5230x2 քմ'
                    },
                    {
                        title: 'Տարածքի բարեկարգման ընդհանուր մակերեսը',
                        text: '5000 քմ'
                    },
                    {
                        title: 'Պատվիրատու',
                        text: '<<ԿԱՄ Դեվելոփմենթս>> ՍՊԸ'
                    },
                    {
                        title: 'Կատարման տարեթիվ',
                        text: '2020-2022'
                    }
                ],
                thumbnail: require('@/assets/images/projects/domus/1.jpg'),
                images: [
                    {src: require('@/assets/images/projects/domus/1.jpg')},
                    {src: require('@/assets/images/projects/domus/2.jpg')},
                    {src: require('@/assets/images/projects/domus/3.jpg')},
                    {src: require('@/assets/images/projects/domus/4.jpg')},
                    {src: require('@/assets/images/projects/domus/5.jpg')},
                    {src: require('@/assets/images/projects/domus/6.jpg')},
                    {src: require('@/assets/images/projects/domus/7.jpg')},
                    {src: require('@/assets/images/projects/domus/8.jpg')},
                    {src: require('@/assets/images/projects/domus/9.jpg')}
                ]
            },
            {
                id: 8,
                title: 'Բենզալցակայան ք․ Վանաձորում',
                description: 'Բենզալցակայանի կառուցապատման աշխատանքներ ք․ Վանաձորում',
                texts: [],
                thumbnail: require('@/assets/images/projects/gasoline-2/1.jpg'),
                images: [
                    {src: require('@/assets/images/projects/gasoline-2/1.jpg')},
                    {src: require('@/assets/images/projects/gasoline-2/2.jpg')},
                    {src: require('@/assets/images/projects/gasoline-2/3.jpg')}
                ]
            },
            {
                id: 9,
                title: 'Սարամեջ ավտոճանապարհի հիմնանորոգման աշխատանքներ',
                description: 'Մ-3 Սարամեջ ավտոճանապարհի կմ0+000-կմ4+300 հիմնանորոգման աշխատանքներ',
                texts: [
                    {
                        title: 'Ընդհանուր երկարություն',
                        text: '4,3 կմ'
                    },
                    {
                        title: 'Ընդհանուր մակերեսը',
                        text: '30000 քմ'
                    },
                    {
                        title: 'Պատվիրատու',
                        text: 'ՀՀ տարածքային կառավարման և ենթակառուցվածքների նախարարություն'
                    },
                    {
                        title: 'Կատարման տարեթիվ',
                        text: '2022-2023'
                    }
                ],
                thumbnail: require('@/assets/images/projects/saramej/1.jpg'),
                images: [
                    {src: require('@/assets/images/projects/saramej/1.jpg')},
                    {src: require('@/assets/images/projects/saramej/2.jpg')},
                    {src: require('@/assets/images/projects/saramej/3.jpg')},
                    {src: require('@/assets/images/projects/saramej/4.jpg')},
                    {src: require('@/assets/images/projects/saramej/5.jpg')},
                    {src: require('@/assets/images/projects/saramej/6.jpg')},
                    {src: require('@/assets/images/projects/saramej/7.jpg')},
                    {src: require('@/assets/images/projects/saramej/8.jpg')},
                    {src: require('@/assets/images/projects/saramej/9.jpg')}
                ]
            },
            {
                id: 10,
                title: 'Մարդու իրավունքների այգի',
                description: 'Մարդու իրավունքների այգու կառուցման աշխատանքներ Վանաձոր համայնքի Տարոն-4 թաղամասում',
                texts: [
                    {
                        title: 'Ընդհանուր մակերեսը',
                        text: '6000 քմ'
                    },
                    {
                        title: 'Պատվիրատու',
                        text: 'Վանաձորի համայնքապետարան'
                    },
                    {
                        title: 'Կատարման տարեթիվ',
                        text: '2020'
                    }
                ],
                thumbnail: require('@/assets/images/projects/taron4-garden/1.jpg'),
                images: [
                    {src: require('@/assets/images/projects/taron4-garden/1.jpg')},
                    {src: require('@/assets/images/projects/taron4-garden/2.jpg')},
                    {src: require('@/assets/images/projects/taron4-garden/3.jpg')},
                    {src: require('@/assets/images/projects/taron4-garden/4.jpg')},
                    {src: require('@/assets/images/projects/taron4-garden/5.jpg')},
                    {src: require('@/assets/images/projects/taron4-garden/6.jpg')}
                ]
            },
            {
                id: 11,
                title: 'Բենզալցակայան ք․ Վանաձորում',
                description: 'Բենզալցակայանի կառուցապատման աշխատանքներ ք․ Վանաձորում',
                texts: [],
                thumbnail: require('@/assets/images/projects/gasoline-3/1.jpg'),
                images: [
                    {src: require('@/assets/images/projects/gasoline-3/1.jpg')},
                    {src: require('@/assets/images/projects/gasoline-3/2.jpg')},
                    {src: require('@/assets/images/projects/gasoline-3/3.jpg')}
                ],
                // videos: [
                //     {src: 'video-1.mp4', thumbnailSrc: 'thumbnail-1.png'},
                //     {src: 'video-2.mp4', thumbnailSrc: 'thumbnail-2.png'},
                //     {src: 'video-3.mp4', thumbnailSrc: 'thumbnail-3.png'},
                //     {src: 'video-4.mp4', thumbnailSrc: 'thumbnail-4.png'}
                // ]
            }
        ]
    },
    mutations: {
        // setProjects(state, payload) {
        //     state.video = payload;
        // }
    },
    actions: {
        // async getProjects({dispatch, commit, state}, data) {
        //     return new Promise((resolve, reject) => {
        //         ApiService.post('...', data).then((response) => {
        //             resolve(response.data);
        //         }).catch((err) => {
        //             reject(err);
        //         })
        //     });
        // }
    },
    getters: {
        // getProjects(state) {
        //     return state.video;
        // }
    }
}
