/**
 * @param path
 * @returns {string}
 */
export const asset = (path) => `${window.location.origin}/${path}`

/**
 * @param string
 * @param limit
 * @returns {string|*}
 */
export const limit = (string, limit) => string.length > limit ? string.slice(0, limit) + '...' : string
